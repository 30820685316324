
@media screen and (max-device-width:640px), screen and (max-width:800px) {
body {

font-size:16px;
font-family:Arial, Helvetica, sans-serif;
width:100% !important;
margin:0px !important;
padding:0px !important;
overflow: auto !important;

}

input[type=text] {
font-size: 16px !important;
vertical-align: middle !important;
line-height: 40px !important;
display: inline-block !important;
}
}


body {
  margin: 0;
  font-family:'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
overflow: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
